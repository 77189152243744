import * as React from "react"
import {useState} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo";

const IndexPage = () => {

  let [loading,setLoading] = useState(true);

  return (
    <Layout>
    <Seo title="e n l a c e s" />
      {
        loading &&
        <div style={{margin:0,padding:0,width:'100vw',height:'100vh',display:'block',background:'red',position:'absolute', backgroundColor:'#d1d1d1'}}></div>
      }
    <iframe title="NaturalMente ~ Enlaces" id = "pdf" seamless={true}  onLoad={()=>{setLoading(false);}} style={{visibility:loading?'hidden':'visible', outline:'none',border:'none', height:'100vh',background:'white',overflow:'hidden'}}  frameBorder={0} src="https://drive.google.com/file/d/1c0AiSv8o9s_e5qqKqI-KB_7rFI4z9aRp/preview" width="100%" height=""></iframe>

    </Layout>
  )
}
  


export default IndexPage

